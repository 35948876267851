<template>
  <div>
    <!-- dialog -->
    <van-dialog v-model="notifyModal" title="通知">
      <div
        style="word-break: break-all; padding: 20px"
        v-html="currentData.message"
      ></div>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar title="通知">
        <template #left>
          <van-icon
            name="arrow-left"
            @click="$router.push($cookie.get('prevPage') || '/')"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <!-- <van-button type="primary" plain style="margin: 0 16px" @click="read"
        >全部標示為已讀</van-button
      > -->

      <van-cell-group
        inset
        v-for="list in annList"
        :key="list.id"
        @click="showContent(list)"
        style="margin-top: 10px"
      >
        <van-cell :title="list.updated_at" :label="list.message">
          <template #title>
            <span class="custom-title">{{ list.updated_at }}</span>
            <!-- <van-tag type="danger">标签</van-tag> -->
          </template>
        </van-cell>
      </van-cell-group>
      <div v-if="!annList.length" class="noData">暂无通知</div>
    </div>
    <foot />
  </div>
</template>
<script>
import foot from "../../components/footer";
export default {
  components: { foot },
  data() {
    return {
      currentOrder: {},
      setOrderModal: false,
      orderForm: {},
      orderErrorModal: false,
      errorSelect: false,
      errorType: "",
      errorTypeCh: "",
      files: [],
      paginate: {},
      annList: [],
      currentPage: 1,
      current_page: 1,
      next_page: false,
      notifyModal: false,
      currentData: {},
    };
  },
  computed: {},
  watch: {
    currentPage() {
      this.getAnn();
    },
  },
  methods: {
    read() {
      this.$http.post(`/mobile/read`).then((res) => {
        if (res.data.code == 0) {
          this.$store.dispatch("message/setUnreadNum", 0);
        }
      });
    },
    showContent(list) {
      this.$dialog.confirm({
        title: "通知",
        message: list.message,
        showCancelButton: false,
      });
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    getAnn() {
      this.$http.get(`/mobile/chat?page=${this.current_page}`).then((res) => {
        if (res.data.code == 0) {
          // let unRead
          let listData = JSON.parse(JSON.stringify(this.annList));
          res.data.data.forEach((data) => {
            let have = false;
            this.annList.forEach((e) => {
              if (e.id == data.id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.annList = listData;
          this.next_page = res.data.next_page;
          this.current_page = res.data.current_page;
        }
      });
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          this.current_page += 1;
          this.getAnn();
        }
      }
    },
  },
  created() {
    this.getAnn();
    this.read();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>

<style lang="scss" scoped>
.paginate {
  margin-top: auto;
  margin-bottom: 30px;
}
.wrap {
  // background-image: url("/home-page_bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}
.bulletinList {
  position: relative;
  cursor: pointer;
  ::before {
    content: "";
    position: absolute;
    left: 3px;
    width: 8px;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: red;
    border-radius: 50%;
  }
  // background-color: red;
}
.noData {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-dialog',{attrs:{"title":"通知"},model:{value:(_vm.notifyModal),callback:function ($$v) {_vm.notifyModal=$$v},expression:"notifyModal"}},[_c('div',{staticStyle:{"word-break":"break-all","padding":"20px"},domProps:{"innerHTML":_vm._s(_vm.currentData.message)}})]),_c('van-sticky',[_c('van-nav-bar',{attrs:{"title":"通知"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){_vm.$router.push(_vm.$cookie.get('prevPage') || '/')}}})]},proxy:true}])})],1),_c('div',{staticClass:"wrap",style:({
      backgroundImage:
        'url(' +
        _vm.$returnColor().path +
        (_vm.$store.state.selfData.member_id
          ? '/home-page_bg.png'
          : '/home-page_bg.png') +
        ')',
    })},[_vm._l((_vm.annList),function(list){return _c('van-cell-group',{key:list.id,staticStyle:{"margin-top":"10px"},attrs:{"inset":""},on:{"click":function($event){return _vm.showContent(list)}}},[_c('van-cell',{attrs:{"title":list.updated_at,"label":list.message},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"custom-title"},[_vm._v(_vm._s(list.updated_at))])]},proxy:true}],null,true)})],1)}),(!_vm.annList.length)?_c('div',{staticClass:"noData"},[_vm._v("暂无通知")]):_vm._e()],2),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }